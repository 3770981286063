<template>
  <div class="relative">
    <div _ngcontent-bdp-c0="" id="ovalesnap" :class="this.isMobile ? 'hidden' : 'block'">
      <svg id="drawOvalesnap" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" version="1.1"
        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" viewBox="0 0 1400 700"
        style="transform-origin: 20% center; transform: perspective(100em) rotateX(40deg)">
        <g transform="translate(290 300) scale(0.6 0.6)">
          <defs id="SvgjsDefs1015"></defs>
          <rect id="S2"
            d="M1016 197.5H1226V197.5C1254.8 197.2 1278 173.8 1278 145S1254.8 92.8 1226 92.5V92.5H876L1016 197.5Z "
            class="ovale-snap-point" bet_list="CH8 CH43 CH16 CH52 CH30 CH36" width="300" height="105" x="966" y="92.5" ></rect>
          <rect id="OR" d="M666 92.5H876L1016 197.5H666V92.5Z " class="ovale-snap-point"
            bet_list="PL1 CH9 CH17 CH20 CH34" width="300" height="105" x="666" y="92.5" ></rect>
          <rect id="S1" width="350" height="105" x="316" y="92.5" class="ovale-snap-point"
            bet_list="TP2 TP2 CH7 CH15 CH21 CH22 CR18 CR18 CH35"></rect>
          <rect id="VI"
            d="M176 92.51V92.5H316V197.5H176V197.49C147.24 197.22 124 173.83 124 145S147.24 92.78 176 92.51Z "
            class="ovale-snap-point" bet_list="CH3 CH15 PL26 CH35" width="200" height="105" x="116" y="92.5"></rect>
            <rect v-for="rect in rects" :id="rect.id" width="70" height="70" :x="rect.x" :y="rect.y" class="ovale-snap-point"
            :bet_list="getBetList(rect.betListBase)"></rect>
          <!-- <rect id="o24" width="70" height="70" x="1155" y="214.8" class="ovale-snap-point"
          bet_list="o27 o18 o13 o22 o36 o9 o11 o31 o30 o14 o8 o20 o23 o1 o10 o33 o5 o16 o24 PL24 PL16 PL5 PL33 PL10 PL1 PL23 PL20 PL8 PL14 PL30 PL31 PL11 PL9 PL36 PL22 PL13 PL18 PL27"></rect>
          <rect id="o16" width="70" height="70" x="1085" y="214.8" class="ovale-snap-point"
            bet_list="o13 o29 o36 o18 o11 o22 o30 o9 o8 o31 o23 o14 o10 o20 o5 o1 o24 o33 o16 PL16 PL33 PL24 PL1 PL5 PL20 PL10 PL14 PL23 PL31 PL8 PL9 PL30 PL22 PL11 PL18 PL36 PL29 PL13"></rect>
          <rect id="o33" width="70" height="70" x="1015" y="214.8" class="ovale-snap-point"
            bet_list="o36 o7 o11 o29 o30 o18 o8 o22 o23 o9 o10 o31 o5 o14 o24 o20 o16 o1 o33 PL33 PL1 PL16 PL20 PL24 PL14 PL5 PL31 PL10 PL9 PL23 PL22 PL8 PL18 PL30 PL29 PL11 PL7 PL36"></rect>
          <rect id="o1" width="70" height="70" x="945" y="214.8" class="ovale-snap-point"
            bet_list="o11 o28 o30 o7 o8 o29 o23 o18 o10 o22 o5 o9 o24 o31 o16 o14 o33 o20 o1 PL1 PL20 PL33 PL14 PL16 PL31 PL24 PL9 PL5 PL22 PL10 PL18 PL23 PL29 PL8 PL7 PL30 PL28 PL11"></rect>
          <rect id="o20" width="70" height="70" x="875" y="214.8" class="ovale-snap-point"
            bet_list="o30 o12 o8 o28 o23 o7 o10 o29 o5 o18 o24 o22 o16 o9 o33 o31 o1 o14 o20 PL20 PL14 PL1 PL31 PL33 PL9 PL16 PL22 PL24 PL18 PL5 PL29 PL10 PL7 PL23 PL28 PL8 PL12 PL30"></rect>
          <rect id="o14" width="70" height="70" x="805" y="214.8" class="ovale-snap-point"
            bet_list="o8 o35 o23 o12 o10 o28 o5 o7 o24 o29 o16 o18 o33 o22 o1 o9 o20 o31 o14 PL14 PL31 PL20 PL9 PL1 PL22 PL33 PL18 PL16 PL29 PL24 PL7 PL5 PL28 PL10 PL12 PL23 PL35 PL8"></rect>
          <rect id="o31" width="70" height="70" x="735" y="214.8" class="ovale-snap-point"
          bet_list="o23 o3 o10 o35 o5 o12 o24 o28 o16 o7 o33 o29 o1 o18 o20 o22 o14 o9 o31 PL31 PL9 PL14 PL22 PL20 PL18 PL1 PL29 PL33 PL7 PL16 PL28 PL24 PL12 PL5 PL35 PL10 PL3 PL23"></rect>
          <rect id="o9" width="70" height="70" x="665" y="214.8" class="ovale-snap-point"
            bet_list="o10 o26 o5 o3 o24 o35 o16 o12 o33 o28 o1 o7 o20 o29 o14 o18 o31 o22 o9 PL9 PL22 PL31 PL18 PL14 PL29 PL20 PL7 PL1 PL28 PL33 PL12 PL16 PL35 PL24 PL3 PL5 PL26 PL10"></rect>
          <rect id="o22" width="70" height="70" x="595" y="214.8" class="ovale-snap-point"
            bet_list="o5 o0 o24 o26 o16 o3 o33 o35 o1 o12 o20 o28 o14 o7 o31 o29 o9 o18 o22 PL22 PL18 PL9 PL29 PL31 PL7 PL14 PL28 PL20 PL12 PL1 PL35 PL33 PL3 PL16 PL26 PL24 PL0 PL5"></rect>
          <rect id="o18" width="70" height="70" x="525" y="214.8" class="ovale-snap-point"
            bet_list="o24 o32 o16 o0 o33 o26 o1 o3 o20 o35 o14 o12 o31 o28 o9 o7 o22 o29 o18 PL18 PL29 PL22 PL7 PL9 PL28 PL31 PL12 PL14 PL35 PL20 PL3 PL1 PL26 PL33 PL0 PL16 PL32 PL24"></rect>
          <rect id="o29" width="70" height="70" x="455" y="214.8" class="ovale-snap-point"
            bet_list="o16 o15 o33 o32 o1 o0 o20 o26 o14 o3 o31 o35 o9 o12 o22 o28 o18 o7 o29 PL29 PL7 PL18 PL28 PL22 PL12 PL9 PL35 PL31 PL3 PL14 PL26 PL20 PL0 PL1 PL32 PL33 PL15 PL16"></rect>
          <rect id="o7" width="70" height="70" x="385" y="214.8" class="ovale-snap-point"
            bet_list="o33 o19 o1 o15 o20 o32 o14 o0 o31 o26 o9 o3 o22 o35 o18 o12 o29 o28 o7 PL7 PL28 PL29 PL12 PL18 PL35 PL22 PL3 PL9 PL26 PL31 PL0 PL14 PL32 PL20 PL15 PL1 PL19 PL33"></rect>
          <rect id="o28" width="70" height="70" x="315" y="214.8" class="ovale-snap-point"
            bet_list="o1 o4 o20 o19 o14 o15 o31 o32 o9 o0 o22 o26 o18 o3 o29 o35 o7 o12 o28 PL28 PL12 PL7 PL35 PL29 PL3 PL18 PL26 PL22 PL0 PL9 PL32 PL31 PL15 PL14 PL19 PL20 PL4 PL1"></rect>
          <rect id="o12" width="70" height="70" x="245" y="214.8" class="ovale-snap-point"
            bet_list="o20 o21 o14 o4 o31 o19 o9 o15 o22 o32 o18 o0 o29 o26 o7 o3 o28 o35 o12 PL12 PL35 PL28 PL3 PL7 PL26 PL29 PL0 PL18 PL32 PL22 PL15 PL9 PL19 PL31 PL4 PL14 PL21 PL20"></rect>
          <rect id="o35" width="70" height="70" x="175" y="214.8" class="ovale-snap-point"
            bet_list="o14 o2 o31 o21 o9 o4 o22 o19 o18 o15 o29 o32 o7 o0 o28 o26 o12 o3 o35 PL35 PL3 PL12 PL26 PL28 PL0 PL7 PL32 PL29 PL15 PL18 PL19 PL22 PL4 PL9 PL21 PL31 PL2 PL14"></rect>
          <rect id="o30" width="70" height="70" x="1155" y="5" class="ovale-snap-point"
            bet_list="o2 o20 o25 o1 o17 o33 o34 o16 o6 o24 o27 o5 o13 o10 o36 o23 o11 o8 o30 PL30 PL8 PL11 PL23 PL36 PL10 PL13 PL5 PL27 PL24 PL6 PL16 PL34 PL33 PL17 PL1 PL25 PL20 PL2"></rect>
          <rect id="o11" width="70" height="70" x="1085" y="5" class="ovale-snap-point"
            bet_list="o21 o1 o2 o33 o25 o16 o17 o24 o34 o5 o6 o10 o27 o23 o13 o8 o36 o30 o11 PL11 PL30 PL36 PL8 PL13 PL23 PL27 PL10 PL6 PL5 PL34 PL24 PL17 PL16 PL25 PL33 PL2 PL1 PL21"></rect>
          <rect id="o36" width="70" height="70" x="1015" y="5" class="ovale-snap-point"
            bet_list="o4 o33 o21 o16 o2 o24 o25 o5 o17 o10 o34 o23 o6 o8 o27 o30 o13 o11 o36 PL36 PL11 PL13 PL30 PL27 PL8 PL6 PL23 PL34 PL10 PL17 PL5 PL25 PL24 PL2 PL16 PL21 PL33 PL4"></rect>
          <rect id="o13" width="70" height="70" x="945" y="5" class="ovale-snap-point"
            bet_list="o19 o16 o4 o24 o21 o5 o2 o10 o25 o23 o17 o8 o34 o30 o6 o11 o27 o36 o13 PL13 PL36 PL27 PL11 PL6 PL30 PL34 PL8 PL17 PL23 PL25 PL10 PL2 PL5 PL21 PL24 PL4 PL16 PL19"></rect>
          <rect id="o27" width="70" height="70" x="875" y="5" class="ovale-snap-point"
            bet_list="o15 o24 o19 o5 o4 o10 o21 o23 o2 o8 o25 o30 o17 o11 o34 o36 o6 o13 o27 PL27 PL13 PL6 PL36 PL34 PL11 PL17 PL30 PL25 PL8 PL2 PL23 PL21 PL10 PL4 PL5 PL19 PL24 PL15"></rect>
          <rect id="o6" width="70" height="70" x="805" y="5" class="ovale-snap-point"
            bet_list="o32 o5 o15 o10 o19 o23 o4 o8 o21 o30 o2 o11 o25 o36 o17 o13 o34 o27 o6 PL6 PL27 PL34 PL13 PL17 PL36 PL25 PL11 PL2 PL30 PL21 PL8 PL4 PL23 PL19 PL10 PL15 PL5 PL32"></rect>
          <rect id="o34" width="70" height="70" x="735" y="5" class="ovale-snap-point"
            bet_list="o0 o10 o32 o23 o15 o8 o19 o30 o4 o11 o21 o36 o2 o13 o25 o27 o17 o6 o34 PL34 PL6 PL17 PL27 PL25 PL13 PL2 PL36 PL21 PL11 PL4 PL30 PL19 PL8 PL15 PL23 PL32 PL10 PL0"></rect>
          <rect id="o17" width="70" height="70" x="665" y="5" class="ovale-snap-point"
            bet_list="o26 o23 o0 o8 o32 o30 o15 o11 o19 o36 o4 o13 o21 o27 o2 o6 o25 o34 o17 PL17 PL34 PL25 PL6 PL2 PL27 PL21 PL13 PL4 PL36 PL19 PL11 PL15 PL30 PL32 PL8 PL0 PL23 PL26"></rect>
          <rect id="o25" width="70" height="70" x="595" y="5" class="ovale-snap-point"
            bet_list="o3 o8 o26 o30 o0 o11 o32 o36 o15 o13 o19 o27 o4 o6 o21 o34 o2 o17 o25 PL25 PL17 PL2 PL34 PL21 PL6 PL4 PL27 PL19 PL13 PL15 PL36 PL32 PL11 PL0 PL30 PL26 PL8 PL3"></rect>
          <rect id="o2" width="70" height="70" x="525" y="5" class="ovale-snap-point"
            bet_list="o35 o30 o3 o11 o26 o36 o0 o13 o32 o27 o15 o6 o19 o34 o4 o17 o21 o25 o2 PL2 PL25 PL21 PL17 PL4 PL34 PL19 PL6 PL15 PL27 PL32 PL13 PL0 PL36 PL26 PL11 PL3 PL30 PL35"></rect>
          <rect id="o21" width="70" height="70" x="455" y="5" class="ovale-snap-point"
            bet_list="o12 o11 o35 o36 o3 o13 o26 o27 o0 o6 o32 o34 o15 o17 o19 o25 o4 o2 o21 PL21 PL2 PL4 PL25 PL19 PL17 PL15 PL34 PL32 PL6 PL0 PL27 PL26 PL13 PL3 PL36 PL35 PL11 PL12"></rect>
          <rect id="o4" width="70" height="70" x="385" y="5" class="ovale-snap-point"
            bet_list="o28 o36 o12 o13 o35 o27 o3 o6 o26 o34 o0 o17 o32 o25 o15 o2 o19 o21 o4 PL4 PL21 PL19 PL2 PL15 PL25 PL32 PL17 PL0 PL34 PL26 PL6 PL3 PL27 PL35 PL13 PL12 PL36 PL28"></rect>
          <rect id="o19" width="70" height="70" x="315" y="5" class="ovale-snap-point"
            bet_list="o7 o13 o28 o27 o12 o6 o35 o34 o3 o17 o26 o25 o0 o2 o32 o21 o15 o4 o19 PL19 PL4 PL15 PL21 PL32 PL2 PL0 PL25 PL26 PL17 PL3 PL34 PL35 PL6 PL12 PL27 PL28 PL13 PL7"></rect>
          <rect id="o15" width="70" height="70" x="245" y="5" class="ovale-snap-point"
            bet_list="o29 o27 o7 o6 o28 o34 o12 o17 o35 o25 o3 o2 o26 o21 o0 o4 o32 o19 o15 PL15 PL19 PL32 PL4 PL0 PL21 PL26 PL2 PL3 PL25 PL35 PL17 PL12 PL34 PL28 PL6 PL7 PL27 PL29"></rect>
          <rect id="o32" width="70" height="70" x="175" y="5" class="ovale-snap-point"
            bet_list="o18 o6 o29 o34 o7 o17 o28 o25 o12 o2 o35 o21 o3 o4 o26 o19 o0 o15 o32 PL32 PL15 PL0 PL19 PL26 PL4 PL3 PL21 PL35 PL2 PL12 PL25 PL28 PL17 PL7 PL34 PL29 PL6 PL18"></rect> -->
            <g >
    <rect
      x="1225.375" y="214.62000274658203"
     >
    </rect>
    <path id="o5"
      d="M1225.375 214.62000274658203C1225.205 214.62000274658203 1225.045 214.61000274658204 1224.875 214.61000274658204V284.62000274658203C1225.045 284.62000274658203 1225.205 284.630002746582 1225.375 284.630002746582C1263.915 284.630002746582 1298.805 269.06000274658203 1324.125 243.87000274658203L1274.625 194.37000274658203C1261.975 206.88000274658202 1244.575 214.62000274658203 1225.375 214.62000274658203Z"
      class="ovale-snap-point" :bet_list="getBetList(pathRects['o5'].betListBase)">
    </path>
  </g>
  <g >
    <rect
      x="1295.375009765625" y="145.13999725341796"
      >
    </rect>
    <path id="o10"
      d="M1365.375009765625 145.13999725341796H1295.375009765625V145.12999725341797C1295.235009765625 164.38999725341796 1287.325009765625 181.78999725341797 1274.625009765625 194.36999725341798L1324.125009765625 243.86999725341798C1349.495009765625 218.62999725341797 1365.235009765625 183.71999725341797 1365.375009765625 145.13999725341796Z"
      class="ovale-snap-point" :bet_list="getBetList(pathRects['o10'].betListBase)">
    </path>
  </g>
  <g >
    <rect
      x="1365.2550048828125" y="145.239990234375">
    </rect>
    <path id="o23"
      d="M1365.2550048828125 145.239990234375C1365.2550048828125 106.57999023437503 1349.5850048828124 71.57999023437503 1324.2450048828125 46.24999023437499L1274.7450048828125 95.74999023437499C1287.4150048828126 108.419990234375 1295.2450048828125 125.919990234375 1295.2450048828125 145.249990234375C1295.2450048828125 145.419990234375 1295.2350048828125 145.57999023437497 1295.2350048828125 145.749990234375H1365.2450048828125C1365.2450048828125 145.56999023437498 1365.2550048828125 145.40999023437502 1365.2550048828125 145.239990234375Z"
      class="ovale-snap-point" :bet_list="getBetList(pathRects['o23'].betListBase)">
    </path>
  </g>
  <g >
    <rect
      x="1225.25" y="5.144995117187477"
      >
    </rect>
    <path id="o8"
      d="M1225.25 5.144995117187477C1225.08 5.144995117187477 1224.92 5.154995117187468 1224.75 5.154995117187468V75.16499511718746C1224.92 75.16499511718746 1225.08 75.15499511718747 1225.25 75.15499511718747C1244.58 75.15499511718747 1262.08 82.99499511718744 1274.75 95.65499511718747L1324.25 46.15499511718747C1298.91 20.814995117187493 1263.91 5.144995117187477 1225.25 5.144995117187477Z"
      class="ovale-snap-point" :bet_list="getBetList(pathRects['o8'].betListBase)">
    </path>
  </g>
  <g >
    <rect
      x="125.5" y="193.8"
      >
    </rect>
    <path id="o3"
      d="M125.5 193.8L76 243.3C101.3 268.8 136.3 284.7 175 284.8V214.8C155.6 214.60000000000002 138.1 206.60000000000002 125.5 193.8Z"
      class="ovale-snap-point" :bet_list="getBetList(pathRects['o3'].betListBase)">
    </path>
  </g>
  <g >
    <rect
      x="105.5" y="144.5"
      >
    </rect>
    <path id="o26"
      d="M105.5 144.5H35.5C35.5 144.7 35.5 144.8 35.5 145C35.5 183.4 51 218.2 76 243.5L125.5 194C113.1 181.4 105.5 164.1 105.5 145C105.5 144.8 105.5 144.7 105.5 144.5Z"
      class="ovale-snap-point" :bet_list="getBetList(pathRects['o26'].betListBase)">
    </path>
  </g>
  <g >
    <rect
      x="175.05" y="4.950000000000003"
     >
    </rect>
    <path id="o0"
      d="M175.05 74.95V4.950000000000003C136.55 5.0500000000000025 101.75 20.750000000000004 76.55 45.95S35.65 105.95 35.55 144.45H105.55C105.64999999999999 125.35000000000001 113.45 108.05 126.05 95.45L126.05 95.45C138.64999999999998 82.95 155.95 75.05 175.05 74.95Z"
      class="ovale-snap-point" :bet_list="getBetList(pathRects['o0'].betListBase)">
    </path>
  </g>
        </g>
         <!-- 定义一个 foreignObject 用来容纳 counter-container -->
    <foreignObject x="1150" y="300" width="40" height="200">
      <body xmlns="http://www.w3.org/1999/xhtml" style="background-color: transparent;">
        <div class="counter-container">
          <button @click="increase" class="counter-button">+</button>
          <span class="counter-number">{{ this.$store.state.ovalNumber }}</span>
          <button @click="decrease" class="counter-button">-</button>
        </div>
      </body>
    </foreignObject>
      </svg>
    </div>
    <!-- mobile -->
    <div _ngcontent-bdp-c0="" id="ovalesnap" :class="this.isMobile ? 'mobile-ovale-snap absolute' : 'hidden'">
      <svg id="drawOvalesnap" width="80vh" height="40vw" xmlns="http://www.w3.org/2000/svg" version="1.1"
        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" viewBox="0 0 1400 290">
        <g>
          <defs id="SvgjsDefs1015"></defs>
          <rect id="S2"
            d="M1016 197.5H1226V197.5C1254.8 197.2 1278 173.8 1278 145S1254.8 92.8 1226 92.5V92.5H876L1016 197.5Z "
            class="ovale-snap-point" bet_list="CH8 CH43 CH16 CH52 CH30 CH36"></rect>
          <rect id="OR" d="M666 92.5H876L1016 197.5H666V92.5Z " class="ovale-snap-point"
            bet_list="PL1 CH9 CH17 CH20 CH34"></rect>
          <rect id="S1" width="350" height="105" x="316" y="92.5" class="ovale-snap-point"
            bet_list="TP2 TP2 CH7 CH15 CH21 CH22 CR18 CR18 CH35"></rect>
          <rect id="VI"
            d="M176 92.51V92.5H316V197.5H176V197.49C147.24 197.22 124 173.83 124 145S147.24 92.78 176 92.51Z "
            class="ovale-snap-point" bet_list="CH3 CH15 PL26 CH35"></rect>
          <rect id="o24" width="70" height="70" x="1155" y="214.8" class="ovale-snap-point"
            bet_list="o10 o5 o24 o16 o33 PL5 PL24 PL16 PL33 PL10"></rect>
          <rect id="o16" width="70" height="70" x="1085" y="214.8" class="ovale-snap-point"
            bet_list="o5 o24 o16 o33 o1 PL5 PL24 PL16 PL33 PL1"></rect>
          <rect id="o33" width="70" height="70" x="1015" y="214.8" class="ovale-snap-point"
            bet_list="o24 o16 o33 o1 o20 PL24 PL16 PL33 PL1 PL20"></rect>
          <rect id="o1" width="70" height="70" x="945" y="214.8" class="ovale-snap-point"
            bet_list="o16 o33 o1 o20 o14 PL16 PL33 PL1 PL20 PL14"></rect>
          <rect id="o20" width="70" height="70" x="875" y="214.8" class="ovale-snap-point"
            bet_list="o33 o1 o20 o14 o31 PL1 PL20 PL14 PL31 PL33"></rect>
          <rect id="o14" width="70" height="70" x="805" y="214.8" class="ovale-snap-point"
            bet_list="o1 o20 o14 o31 o9 PL1 PL20 PL14 PL31 PL9"></rect>
          <rect id="o31" width="70" height="70" x="735" y="214.8" class="ovale-snap-point"
            bet_list="o20 o14 o31 o9 o22 PL20 PL14 PL31 PL9 PL22"></rect>
          <rect id="o9" width="70" height="70" x="665" y="214.8" class="ovale-snap-point"
            bet_list="o14 o31 o9 o22 o18 PL14 PL31 PL9 PL22 PL18"></rect>
          <rect id="o22" width="70" height="70" x="595" y="214.8" class="ovale-snap-point"
            bet_list="o31 o9 o22 o18 o29 PL31 PL9 PL22 PL18 PL29"></rect>
          <rect id="o18" width="70" height="70" x="525" y="214.8" class="ovale-snap-point"
            bet_list="o9 o22 o18 o29 o7 PL9 PL22 PL18 PL29 PL7"></rect>
          <rect id="o29" width="70" height="70" x="455" y="214.8" class="ovale-snap-point"
            bet_list="o22 o18 o29 o7 o28 PL22 PL18 PL29 PL7 PL28"></rect>
          <rect id="o7" width="70" height="70" x="385" y="214.8" class="ovale-snap-point"
            bet_list="o18 o29 o7 o28 o12 PL18 PL29 PL7 PL28 PL12"></rect>
          <rect id="o28" width="70" height="70" x="315" y="214.8" class="ovale-snap-point"
            bet_list="o29 o7 o28 o12 o35 PL29 PL7 PL28 PL12 PL35"></rect>
          <rect id="o12" width="70" height="70" x="245" y="214.8" class="ovale-snap-point"
            bet_list="o7 o28 o12 o35 o3 PL7 PL28 PL12 PL35 PL3"></rect>
          <rect id="o35" width="70" height="70" x="175" y="214.8" class="ovale-snap-point"
            bet_list="o28 o12 o35 o3 o26 PL28 PL12 PL35 PL3 PL26"></rect>
          <rect id="o30" width="70" height="70" x="1155" y="5" class="ovale-snap-point"
            bet_list="o36 o11 o30 o8 o23 PL36 PL11 PL30 PL8 PL23"></rect>
          <rect id="o11" width="70" height="70" x="1085" y="5" class="ovale-snap-point"
            bet_list="o13 o36 o11 o30 o8 PL13 PL36 PL11 PL30 PL8"></rect>
          <rect id="o36" width="70" height="70" x="1015" y="5" class="ovale-snap-point"
            bet_list="o27 o13 o36 o11 o30 PL27 PL13 PL36 PL11 PL30"></rect>
          <rect id="o13" width="70" height="70" x="945" y="5" class="ovale-snap-point"
            bet_list="o6 o27 o13 o36 o11 PL6 PL27 PL13 PL36 PL11"></rect>
          <rect id="o27" width="70" height="70" x="875" y="5" class="ovale-snap-point"
            bet_list="o34 o6 o27 o13 o36 PL34 PL6 PL27 PL13 PL36"></rect>
          <rect id="o6" width="70" height="70" x="805" y="5" class="ovale-snap-point"
            bet_list="o17 o34 o6 o27 o13 PL17 PL34 PL6 PL27 PL13"></rect>
          <rect id="o34" width="70" height="70" x="735" y="5" class="ovale-snap-point"
            bet_list="o25 o17 o34 o6 o27 PL25 PL17 PL34 PL6 PL27"></rect>
          <rect id="o17" width="70" height="70" x="665" y="5" class="ovale-snap-point"
            bet_list="o2 o25 o17 o34 o6 PL2 PL25 PL17 PL34 PL6"></rect>
          <rect id="o25" width="70" height="70" x="595" y="5" class="ovale-snap-point"
            bet_list="o21 o2 o25 o17 o34 PL21 PL2 PL25 PL17 PL34"></rect>
          <rect id="o2" width="70" height="70" x="525" y="5" class="ovale-snap-point"
            bet_list="o4 o21 o2 o25 o17 PL4 PL21 PL2 PL25 PL17"></rect>
          <rect id="o21" width="70" height="70" x="455" y="5" class="ovale-snap-point"
            bet_list="o19 o4 o21 o2 o25 PL19 PL4 PL21 PL2 PL25"></rect>
          <rect id="o4" width="70" height="70" x="385" y="5" class="ovale-snap-point"
            bet_list="o15 o19 o4 o21 o2 PL15 PL19 PL4 PL21 PL2"></rect>
          <rect id="o19" width="70" height="70" x="315" y="5" class="ovale-snap-point"
            bet_list="o32 o15 o19 o4 o21 PL32 PL15 PL19 PL4 PL21"></rect>
          <rect id="o15" width="70" height="70" x="245" y="5" class="ovale-snap-point"
            bet_list="o0 o32 o15 o19 o4 PL0 PL32 PL15 PL19 PL4"></rect>
          <rect id="o32" width="70" height="70" x="175" y="5" class="ovale-snap-point"
            bet_list="o26 o0 o32 o15 o19 PL26 PL0 PL32 PL15 PL19"></rect>
          <rect id="o5"
            d="M1225.375 214.62000274658203C1225.205 214.62000274658203 1225.045 214.61000274658204 1224.875 214.61000274658204V284.62000274658203C1225.045 284.62000274658203 1225.205 284.630002746582 1225.375 284.630002746582C1263.915 284.630002746582 1298.805 269.06000274658203 1324.125 243.87000274658203L1274.625 194.37000274658203C1261.975 206.88000274658202 1244.575 214.62000274658203 1225.375 214.62000274658203Z "
            class="ovale-snap-point" bet_list="o23 o10 o5 o24 o16 PL23 PL10 PL5 PL24 PL16"></rect>
          <rect id="o10"
            d="M1365.375009765625 145.13999725341796H1295.375009765625V145.12999725341797C1295.235009765625 164.38999725341796 1287.325009765625 181.78999725341797 1274.625009765625 194.36999725341798L1324.125009765625 243.86999725341798C1349.495009765625 218.62999725341797 1365.235009765625 183.71999725341797 1365.375009765625 145.13999725341796Z "
            class="ovale-snap-point" bet_list="o8 o23 o10 o5 o24 PL8 PL23 PL10 PL5 PL24"></rect>
          <rect id="o23"
            d="M1365.2550048828125 145.239990234375C1365.2550048828125 106.57999023437503 1349.5850048828124 71.57999023437503 1324.2450048828125 46.24999023437499L1274.7450048828125 95.74999023437499C1287.4150048828126 108.419990234375 1295.2450048828125 125.919990234375 1295.2450048828125 145.249990234375C1295.2450048828125 145.419990234375 1295.2350048828125 145.57999023437497 1295.2350048828125 145.749990234375H1365.2450048828125C1365.2450048828125 145.56999023437498 1365.2550048828125 145.40999023437502 1365.2550048828125 145.239990234375Z "
            class="ovale-snap-point" bet_list="o30 o8 o23 o10 o5 PL30 PL8 PL23 PL10 PL5"></rect>
          <rect id="o8"
            d="M1225.25 5.144995117187477C1225.08 5.144995117187477 1224.92 5.154995117187468 1224.75 5.154995117187468V75.16499511718746C1224.92 75.16499511718746 1225.08 75.15499511718747 1225.25 75.15499511718747C1244.58 75.15499511718747 1262.08 82.99499511718744 1274.75 95.65499511718747L1324.25 46.15499511718747C1298.91 20.814995117187493 1263.91 5.144995117187477 1225.25 5.144995117187477Z "
            class="ovale-snap-point" bet_list="o11 o30 o8 o23 o10 PL11 PL30 PL8 PL23 PL10"></rect>
          <rect id="o3"
            d="M125.5 193.8L76 243.3C101.3 268.8 136.3 284.7 175 284.8V214.8C155.6 214.60000000000002 138.1 206.60000000000002 125.5 193.8Z "
            class="ovale-snap-point" bet_list="o12 o35 o3 o26 o0 PL12 PL35 PL3 PL26 PL0"></rect>
          <rect id="o26"
            d="M105.5 144.5H35.5C35.5 144.7 35.5 144.8 35.5 145C35.5 183.4 51 218.2 76 243.5L125.5 194C113.1 181.4 105.5 164.1 105.5 145C105.5 144.8 105.5 144.7 105.5 144.5Z "
            class="ovale-snap-point" bet_list="o35 o3 o26 o0 o32 PL35 PL3 PL26 PL0 PL32"></rect>
          <rect id="o0"
            d="M175.05 74.95V4.950000000000003C136.55 5.0500000000000025 101.75 20.750000000000004 76.55 45.95S35.65 105.95 35.55 144.45H105.55C105.64999999999999 125.35000000000001 113.45 108.05 126.05 95.45L126.05 95.45C138.64999999999998 82.95 155.95 75.05 175.05 74.95Z "
            class="ovale-snap-point" bet_list="o3 o26 o0 o32 o15 PL3 PL26 PL0 PL32 PL15"></rect>
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { isMobile } from "../../utils/index.js";

export default {
  data() {
    return {
      hovered: [],
      hoveredPannos: [],
      selected: [],
      isMobile : false,
      pathRects :{
  "o5": {
    "betListBase": ["o6", "o22", "o27", "o9", "o13", "o31", "o36", "o14", "o11", "o20", "o30", "o1", "o8", "o33", "o23", "o16", "o10", "o24", "o5", "PL5", "PL24", "PL10", "PL16", "PL23", "PL33", "PL8", "PL1", "PL30", "PL20", "PL11", "PL14", "PL36", "PL31", "PL13", "PL9", "PL27", "PL22", "PL6"]
  },
  "o10": {
    "betListBase": ["o34", "o9", "o6", "o31", "o27", "o14", "o13", "o20", "o36", "o1", "o11", "o33", "o30", "o16", "o8", "o24", "o23", "o5", "o10", "PL10", "PL5", "PL23", "PL24", "PL8", "PL16", "PL30", "PL33", "PL11", "PL1", "PL36", "PL20", "PL13", "PL14", "PL27", "PL31", "PL6", "PL9", "PL34"]
  },
  "o23": {
    "betListBase": ["o17", "o31", "o34", "o14", "o6", "o20", "o27", "o1", "o13", "o33", "o36", "o16", "o11", "o24", "o30", "o5", "o8", "o10", "o23", "PL23", "PL10", "PL8", "PL5", "PL30", "PL24", "PL11", "PL16", "PL36", "PL33", "PL13", "PL1", "PL27", "PL20", "PL6", "PL14", "PL34", "PL31", "PL17"]
  },
  "o8": {
    "betListBase": ["o25", "o14", "o17", "o20", "o34", "o1", "o6", "o33", "o27", "o16", "o13", "o24", "o36", "o5", "o11", "o10", "o30", "o23", "o8", "PL8", "PL23", "PL30", "PL10", "PL11", "PL5", "PL36", "PL24", "PL13", "PL16", "PL27", "PL33", "PL6", "PL1", "PL34", "PL20", "PL17", "PL14", "PL25"]
  },
  "o3": {
    "betListBase": ["o31", "o25", "o9", "o2", "o22", "o21", "o18", "o4", "o29", "o19", "o7", "o15", "o28", "o32", "o12", "o0", "o35", "o26", "o3", "PL3", "PL26", "PL35", "PL0", "PL12", "PL32", "PL28", "PL15", "PL7", "PL19", "PL29", "PL4", "PL18", "PL21", "PL22", "PL2", "PL9", "PL25", "PL31"]
  },
  "o26": {
    "betListBase": ["o9", "o17", "o22", "o25", "o18", "o2", "o29", "o21", "o7", "o4", "o28", "o19", "o12", "o15", "o35", "o32", "o3", "o0", "o26", "PL26", "PL0", "PL3", "PL32", "PL35", "PL15", "PL12", "PL19", "PL28", "PL4", "PL7", "PL21", "PL29", "PL2", "PL18", "PL25", "PL22", "PL17", "PL9"]
  },
  "o0": {
    "betListBase": ["o22", "o34", "o18", "o17", "o29", "o25", "o7", "o2", "o28", "o21", "o12", "o4", "o35", "o19", "o3", "o15", "o26", "o32", "o0", "PL0", "PL32", "PL26", "PL15", "PL3", "PL19", "PL35", "PL4", "PL12", "PL21", "PL28", "PL2", "PL7", "PL25", "PL29", "PL17", "PL18", "PL34", "PL22"]
  }
},
      rects :[
       
  {
    id: 'o24',
    width: 70,
    height: 70,
    x: 1155,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o27', 'o18', 'o13', 'o22', 'o36', 'o9', 'o11', 'o31', 'o30', 'o14', 'o8', 'o20', 'o23', 'o1', 'o10', 'o33', 'o5', 'o16', 'o24', 'PL24', 'PL16', 'PL5', 'PL33', 'PL10', 'PL1', 'PL23', 'PL20', 'PL8', 'PL14', 'PL30', 'PL31', 'PL11', 'PL9', 'PL36', 'PL22', 'PL13', 'PL18', 'PL27']
  },
  {
    id: 'o16',
    width: 70,
    height: 70,
    x: 1085,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o13', 'o29', 'o36', 'o18', 'o11', 'o22', 'o30', 'o9', 'o8', 'o31', 'o23', 'o14', 'o10', 'o20', 'o5', 'o1', 'o24', 'o33', 'o16', 'PL16', 'PL33', 'PL24', 'PL1', 'PL5', 'PL20', 'PL10', 'PL14', 'PL23', 'PL31', 'PL8', 'PL9', 'PL30', 'PL22', 'PL11', 'PL18', 'PL36', 'PL29', 'PL13']
  },
  {
    id: 'o33',
    width: 70,
    height: 70,
    x: 1015,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o36', 'o7', 'o11', 'o29', 'o30', 'o18', 'o8', 'o22', 'o23', 'o9', 'o10', 'o31', 'o5', 'o14', 'o24', 'o20', 'o16', 'o1', 'o33', 'PL33', 'PL1', 'PL16', 'PL20', 'PL24', 'PL14', 'PL5', 'PL31', 'PL10', 'PL9', 'PL23', 'PL22', 'PL8', 'PL18', 'PL30', 'PL29', 'PL11', 'PL7', 'PL36']
  },
  {
    id: 'o1',
    width: 70,
    height: 70,
    x: 945,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o11', 'o28', 'o30', 'o7', 'o8', 'o29', 'o23', 'o18', 'o10', 'o22', 'o5', 'o9', 'o24', 'o31', 'o16', 'o14', 'o33', 'o20', 'o1', 'PL1', 'PL20', 'PL33', 'PL14', 'PL16', 'PL31', 'PL24', 'PL9', 'PL5', 'PL22', 'PL10', 'PL18', 'PL23', 'PL29', 'PL8', 'PL7', 'PL30', 'PL28', 'PL11']
  },
  {
    id: 'o20',
    width: 70,
    height: 70,
    x: 875,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o30', 'o12', 'o8', 'o28', 'o23', 'o7', 'o10', 'o29', 'o5', 'o18', 'o24', 'o22', 'o16', 'o9', 'o33', 'o31', 'o1', 'o14', 'o20', 'PL20', 'PL14', 'PL1', 'PL31', 'PL33', 'PL9', 'PL16', 'PL22', 'PL24', 'PL18', 'PL5', 'PL29', 'PL10', 'PL7', 'PL23', 'PL28', 'PL8', 'PL12', 'PL30']
  },
  {
    id: 'o14',
    width: 70,
    height: 70,
    x: 805,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o8', 'o35', 'o23', 'o12', 'o10', 'o28', 'o5', 'o7', 'o24', 'o29', 'o16', 'o18', 'o33', 'o22', 'o1', 'o9', 'o20', 'o31', 'o14', 'PL14', 'PL31', 'PL20', 'PL9', 'PL1', 'PL22', 'PL33', 'PL18', 'PL16', 'PL29', 'PL24', 'PL7', 'PL5', 'PL28', 'PL10', 'PL12', 'PL23', 'PL35', 'PL8']
  },
  {
    id: 'o31',
    width: 70,
    height: 70,
    x: 735,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o23', 'o3', 'o10', 'o35', 'o5', 'o12', 'o24', 'o28', 'o16', 'o7', 'o33', 'o29', 'o1', 'o18', 'o20', 'o22', 'o14', 'o9', 'o31', 'PL31', 'PL9', 'PL14', 'PL22', 'PL20', 'PL18', 'PL1', 'PL29', 'PL33', 'PL7', 'PL16', 'PL28', 'PL24', 'PL12', 'PL5', 'PL35', 'PL10', 'PL3', 'PL23']
  },
  {
    id: 'o9',
    width: 70,
    height: 70,
    x: 665,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o10', 'o26', 'o5', 'o3', 'o24', 'o35', 'o16', 'o12', 'o33', 'o28', 'o1', 'o7', 'o20', 'o29', 'o14', 'o18', 'o31', 'o22', 'o9', 'PL9', 'PL22', 'PL31', 'PL18', 'PL14', 'PL29', 'PL20', 'PL7', 'PL1', 'PL28', 'PL33', 'PL12', 'PL16', 'PL35', 'PL24', 'PL3', 'PL5', 'PL26', 'PL10']
  },
  {
    id: 'o22',
    width: 70,
    height: 70,
    x: 595,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o5', 'o0', 'o24', 'o26', 'o16', 'o3', 'o33', 'o35', 'o1', 'o12', 'o20', 'o28', 'o14', 'o7', 'o31', 'o29', 'o9', 'o18', 'o22', 'PL22', 'PL18', 'PL9', 'PL29', 'PL31', 'PL7', 'PL14', 'PL28', 'PL20', 'PL12', 'PL1', 'PL35', 'PL33', 'PL3', 'PL16', 'PL26', 'PL24', 'PL0', 'PL5']
  },
  {
    id: 'o18',
    width: 70,
    height: 70,
    x: 525,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o24', 'o32', 'o16', 'o0', 'o33', 'o26', 'o1', 'o3', 'o20', 'o35', 'o14', 'o12', 'o31', 'o28', 'o9', 'o7', 'o22', 'o29', 'o18', 'PL18', 'PL29', 'PL22', 'PL7', 'PL9', 'PL28', 'PL31', 'PL12', 'PL14', 'PL35', 'PL20', 'PL3', 'PL1', 'PL26', 'PL33',    'PL0', 'PL16', 'PL32', 'PL24']
  },
  {
    id: 'o29',
    width: 70,
    height: 70,
    x: 455,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o16', 'o15', 'o33', 'o32', 'o1', 'o0', 'o20', 'o26', 'o14', 'o3', 'o31', 'o35', 'o9', 'o12', 'o22', 'o28', 'o18', 'o7', 'o29', 'PL29', 'PL7', 'PL18', 'PL28', 'PL22', 'PL12', 'PL9', 'PL35', 'PL31', 'PL3', 'PL14', 'PL26', 'PL20', 'PL0', 'PL1', 'PL32', 'PL33', 'PL15', 'PL16']
  },
  {
    id: 'o7',
    width: 70,
    height: 70,
    x: 385,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o33', 'o19', 'o1', 'o15', 'o20', 'o32', 'o14', 'o0', 'o31', 'o26', 'o9', 'o3', 'o22', 'o35', 'o18', 'o12', 'o29', 'o28', 'o7', 'PL7', 'PL28', 'PL29', 'PL12', 'PL18', 'PL35', 'PL22', 'PL3', 'PL9', 'PL26', 'PL31', 'PL0', 'PL14', 'PL32', 'PL20', 'PL15', 'PL1', 'PL19', 'PL33']
  },
  {
    id: 'o28',
    width: 70,
    height: 70,
    x: 315,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o1', 'o4', 'o20', 'o19', 'o14', 'o15', 'o31', 'o32', 'o9', 'o0', 'o22', 'o26', 'o18', 'o3', 'o29', 'o35', 'o7', 'o12', 'o28', 'PL28', 'PL12', 'PL7', 'PL35', 'PL29', 'PL3', 'PL18', 'PL26', 'PL22', 'PL0', 'PL9', 'PL32', 'PL31', 'PL15', 'PL14', 'PL19', 'PL20', 'PL4', 'PL1']
  },
  {
    id: 'o12',
    width: 70,
    height: 70,
    x: 245,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o20', 'o21', 'o14', 'o4', 'o31', 'o19', 'o9', 'o15', 'o22', 'o32', 'o18', 'o0', 'o29', 'o26', 'o7', 'o3', 'o28', 'o35', 'o12', 'PL12', 'PL35', 'PL28', 'PL3', 'PL7', 'PL26', 'PL29', 'PL0', 'PL18', 'PL32', 'PL22', 'PL15', 'PL9', 'PL19', 'PL31', 'PL4', 'PL14', 'PL21', 'PL20']
  },
  {
    id: 'o35',
    width: 70,
    height: 70,
    x: 175,
    y: 214.8,
    class: 'ovale-snap-point',
    betListBase: ['o14', 'o2', 'o31', 'o21', 'o9', 'o4', 'o22', 'o19', 'o18', 'o15', 'o29', 'o32', 'o7', 'o0', 'o28', 'o26', 'o12', 'o3', 'o35', 'PL35', 'PL3', 'PL12', 'PL26', 'PL28', 'PL0', 'PL7', 'PL32', 'PL29', 'PL15', 'PL18', 'PL19', 'PL22', 'PL4', 'PL9', 'PL21', 'PL31', 'PL2', 'PL14']
  },
  {
    id: 'o30',
    width: 70,
    height: 70,
    x: 1155,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o2', 'o20', 'o25', 'o1', 'o17', 'o33', 'o34', 'o16', 'o6', 'o24', 'o27', 'o5', 'o13', 'o10', 'o36', 'o23', 'o11', 'o8', 'o30', 'PL30', 'PL8', 'PL11', 'PL23', 'PL36', 'PL10', 'PL13', 'PL5', 'PL27', 'PL24', 'PL6', 'PL16', 'PL34', 'PL33', 'PL17', 'PL1', 'PL25', 'PL20', 'PL2']
  },
  {
    id: 'o11',
    width: 70,
    height: 70,
    x: 1085,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o21', 'o1', 'o2', 'o33', 'o25', 'o16', 'o17', 'o24', 'o34', 'o5', 'o6', 'o10', 'o27', 'o23', 'o13', 'o8', 'o36', 'o30', 'o11', 'PL11', 'PL30', 'PL36', 'PL8', 'PL13', 'PL23', 'PL27', 'PL10', 'PL6', 'PL5', 'PL34', 'PL24', 'PL17', 'PL16', 'PL25', 'PL33', 'PL2', 'PL1', 'PL21']
  },
  {
    id: 'o36',
    width: 70,
    height: 70,
    x: 1015,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o4', 'o33', 'o21', 'o16', 'o2', 'o24', 'o25', 'o5', 'o17', 'o34', 'o10', 'o34', 'o23', 'o6', 'o8', 'o27', 'o30', 'o13', 'o11', 'o36', 'PL36', 'PL11', 'PL13', 'PL30', 'PL27', 'PL8', 'PL6', 'PL23', 'PL34', 'PL10', 'PL17', 'PL5', 'PL25', 'PL24', 'PL2', 'PL16', 'PL21', 'PL33', 'PL4']
  },
  {
    id: 'o13',
    width: 70,
    height: 70,
    x: 945,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o19', 'o16', 'o4', 'o24', 'o21', 'o5', 'o2', 'o10', 'o25', 'o23', 'o17', 'o8', 'o34', 'o30', 'o6', 'o11', 'o27', 'o36', 'o13', 'PL13', 'PL36', 'PL27', 'PL11', 'PL6', 'PL30', 'PL34', 'PL8', 'PL17', 'PL23', 'PL25', 'PL10', 'PL2', 'PL5', 'PL21', 'PL24', 'PL4', 'PL16', 'PL19']
  },
  {
    id: 'o27',
    width: 70,
    height: 70,
    x: 875,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o15', 'o24', 'o19', 'o5', 'o4', 'o10', 'o21', 'o23', 'o2', 'o8', 'o25', 'o30', 'o17', 'o11', 'o34', 'o36', 'o6', 'o13', 'o27', 'PL27', 'PL13', 'PL6', 'PL36', 'PL34', 'PL11', 'PL17', 'PL30', 'PL25', 'PL8', 'PL2', 'PL23', 'PL21', 'PL10', 'PL4', 'PL5', 'PL19', 'PL24', 'PL15']
  },
  {
    id: 'o6',
    width: 70,
    height: 70,
    x: 805,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o32', 'o5', 'o15', 'o10', 'o19', 'o23', 'o4', 'o8', 'o21', 'o30', 'o2', 'o11', 'o25', 'o36', 'o17', 'o13', 'o34', 'o27', 'o6', 'PL6', 'PL27', 'PL34', 'PL13', 'PL17', 'PL36', 'PL25', 'PL11', 'PL2', 'PL30', 'PL21', 'PL8', 'PL4', 'PL23', 'PL19', 'PL10', 'PL15', 'PL5', 'PL32']
  },
  {
    id: 'o34',
    width: 70,
    height: 70,
    x: 735,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o0', 'o10', 'o32', 'o23', 'o15', 'o8', 'o19', 'o30', 'o4', 'o11', 'o21', 'o36', 'o2', 'o13', 'o25', 'o27', 'o17', 'o6', 'o34', 'PL34', 'PL6', 'PL17', 'PL27', 'PL25', 'PL13', 'PL2', 'PL36', 'PL21', 'PL11', 'PL4', 'PL30', 'PL19', 'PL8', 'PL15', 'PL23', 'PL32', 'PL10', 'PL0']
  },
  {
    id: 'o17',
    width: 70,
    height: 70,
    x: 665,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o26', 'o23', 'o0', 'o8', 'o32', 'o30', 'o15', 'o11', 'o19', 'o36', 'o4', 'o13', 'o21', 'o27', 'o2', 'o6', 'o25', 'o34', 'o17', 'PL17', 'PL34', 'PL25', 'PL6', 'PL2', 'PL27', 'PL21', 'PL13', 'PL4', 'PL36', 'PL19', 'PL11', 'PL15', 'PL30', 'PL32', 'PL8', 'PL0', 'PL23', 'PL26']
  },
  {
    id: 'o25',
    width: 70,
    height: 70,
    x: 595,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o3', 'o8', 'o26', 'o30', 'o0', 'o11', 'o32', 'o36', 'o15', 'o13', 'o19', 'o27', 'o4', 'o6', 'o21', 'o34', 'o2', 'o17', 'o25', 'PL25', 'PL17', 'PL2', 'PL34', 'PL21', 'PL6', 'PL4', 'PL27', 'PL19', 'PL13', 'PL15', 'PL36', 'PL32', 'PL11', 'PL0', 'PL30', 'PL26', 'PL8', 'PL3']
  },
  {
    id: 'o2',
    width: 70,
    height: 70,
    x: 525,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o35', 'o30', 'o3', 'o11', 'o26', 'o36', 'o0', 'o13', 'o32', 'o27', 'o15', 'o6', 'o19', 'o34', 'o4', 'o17', 'o21', 'o25', 'o2', 'PL2', 'PL25', 'PL21', 'PL17', 'PL4', 'PL34', 'PL19', 'PL6', 'PL15', 'PL27', 'PL32', 'PL13', 'PL0', 'PL36', 'PL26', 'PL11', 'PL3', 'PL30', 'PL35']
  },
  {
    id: 'o21',
    width: 70,
    height: 70,
    x: 455,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o12', 'o11', 'o35', 'o36', 'o3', 'o13', 'o26', 'o27', 'o0', 'o6', 'o32', 'o34', 'o15', 'o17', 'o19', 'o25', 'o4', 'o2', 'o21', 'PL21', 'PL2', 'PL4', 'PL25', 'PL19', 'PL17', 'PL15', 'PL34', 'PL32', 'PL6', 'PL0', 'PL27', 'PL26', 'PL13', 'PL3', 'PL36', 'PL35', 'PL11', 'PL12']
  },
  {
    id: 'o4',
    width: 70,
    height: 70,
    x: 385,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o28', 'o36', 'o12', 'o13', 'o35', 'o27', 'o3', 'o6', 'o26', 'o34', 'o0', 'o17', 'o32', 'o25', 'o15', 'o2', 'o19', 'o21', 'o4', 'PL4', 'PL21', 'PL19', 'PL2', 'PL15', 'PL25', 'PL32', 'PL17', 'PL0', 'PL34', 'PL26', 'PL6', 'PL3', 'PL27', 'PL35', 'PL13', 'PL12', 'PL36', 'PL28']
  },
  {
    id: 'o19',
    width: 70,
    height: 70,
    x: 315,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o7', 'o13', 'o28', 'o27', 'o12', 'o6', 'o35', 'o34', 'o3', 'o17', 'o26', 'o25', 'o0', 'o2', 'o32', 'o21', 'o15', 'o4', 'o19', 'PL19', 'PL4', 'PL15', 'PL21', 'PL32', 'PL2', 'PL0', 'PL25', 'PL26', 'PL17', 'PL3', 'PL34', 'PL35', 'PL6', 'PL12', 'PL27', 'PL28', 'PL13', 'PL7']
  },
  {
    id: 'o15',
    width: 70,
    height: 70,
    x: 245,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o29', 'o27', 'o7', 'o6', 'o28', 'o34', 'o12', 'o17', 'o35', 'o25', 'o3', 'o2', 'o26', 'o21', 'o0', 'o4', 'o32', 'o19', 'o15', 'PL15', 'PL19', 'PL32', 'PL4', 'PL0', 'PL21', 'PL26', 'PL2', 'PL3', 'PL25', 'PL35', 'PL17', 'PL12', 'PL34', 'PL28', 'PL6', 'PL7', 'PL27', 'PL29']
  },
  {
    id: 'o32',
    width: 70,
    height: 70,
    x: 175,
    y: 5,
    class: 'ovale-snap-point',
    betListBase: ['o18', 'o6', 'o29', 'o34', 'o7', 'o17', 'o28', 'o25', 'o12', 'o2', 'o35', 'o21', 'o3', 'o4', 'o26', 'o19', 'o0', 'o15', 'o32', 'PL32', 'PL15', 'PL0', 'PL19', 'PL26', 'PL4', 'PL3', 'PL21', 'PL35', 'PL2', 'PL12', 'PL25', 'PL28', 'PL17', 'PL7', 'PL34', 'PL29', 'PL6', 'PL18']
  }
]
    };
  },
  methods: {
    increase() {
      var number = this.$store.state.ovalNumber;
      if (number < 9) {
        number += 1;
        this.$store.commit("setOvalNumber", number);
      }
    },
    decrease() {
      var number = this.$store.state.ovalNumber;
      if (number > 2) {
        number -= 1;
        this.$store.commit("setOvalNumber", number);
      }
    },
     getBetList(betList) {
          const numberOfItems = this.$store.state.ovalNumber * 2 + 1;
          const oList = betList.filter(item => item.startsWith('o')).slice(-numberOfItems);
          const plList = betList.filter(item => item.startsWith('PL')).slice(0, numberOfItems);
          return [...oList, ...plList].join(' ');
    },
    setSelected(_selected) {
      const _hovers = _selected.trim().includes(" ")
        ? _selected.split(" ")
        : [_selected.trim()];
      this.selected = this.$store.state.selected;
      const updated = [];
      let userBalance = this.$store.state.haveBalance;
      let error = false;
      console.log(this.$store.state.betAction === 'add', userBalance,this.$store.state.coin)
      for (const id of _hovers) {
        if (id.startsWith('o')) {
          continue;
        }
        updated.push({ refer: id, value: this.$store.state.coin });
        if (this.selected.filter((f) => f.refer == id).length == 0) {
          if ((eval(userBalance) - eval(this.$store.state.coin)) > 0) {
            this.selected.push({ refer: id, value: this.$store.state.coin });
            userBalance -= this.$store.state.coin;
            if(this.$store.state.coin < this.$store.state.minBet ){
            this.$appToast(`min ${this.$store.state.minBet} EUR`,'','#ff9000')
          }
          }
          else {
            
            if (this.$store.state.betAction === 'add')
              error = true;
          }
        }
        else {
          if ((eval(userBalance) - eval(this.$store.state.coin)) > 0) {
            if((this.selected.filter((f) => f.refer == id)[0].value + this.$store.state.coin) <= this.$store.state.maxBet){
              this.selected.filter((f) => f.refer == id)[0].value += this.$store.state.coin;
              userBalance -= this.$store.state.coin;
              if((this.selected.filter((f) => f.refer == id)[0].value + this.$store.state.coin) < this.$store.state.minBet ){
            this.$appToast(`min ${this.$store.state.minBet} EUR`,'','#ff9000')
            }
            }else {
                this.$appToast("max",'','#ff9000')
              }
          }
          else {
            if (this.$store.state.betAction === 'add')
              error = true;
          }
        }
      }
      if (error) {
        // let instance = Vue.$toast.error(`Limit balance, please check your balance!`);
        this.$appToast("check")

      }
      this.$store.commit('setUpdated', updated);
    },
    setHovered(_hovered, isOvale) {
      const _hovers = _hovered.trim().includes(" ")
        ? _hovered.split(" ")
        : [_hovered.trim()];
      const _hoverPannos = [];
      const _coinPannos = [];

      for (const id of _hovers) {
        if (id == "") continue;
        // if(this.selected.filter((f)=>f.refer==id).length == 0){
        //     this.selected.push({refer:id,value:this.$store.state.coin});
        // }
        if (isOvale) {
          const hover = document.getElementById(id).getAttribute("hover");
          // select component on panno
          if (hover && hover != null) {
            const hovers = hover.includes(" ") ? hover.split(" ") : [hover.trim()];

            for (const pannoId of hovers) {
              _hoverPannos.push({ refer: `g${pannoId}` });
              _coinPannos.push({ refer: pannoId });
            }
          }
          // select component on racetrack
          else {
            const elements = document.querySelectorAll(`#${id}`);
            for (const element of elements) {
              element.classList.remove("hover-ovale-snap");
              element.classList.add("hover-ovale-snap");
            }
          }
        }
      }
      this.$store.commit("setHovered", _hoverPannos);
    },
  },
  mounted() {
    const snapPoints = document.getElementsByClassName("ovale-snap-point");
    this.isMobile = isMobile();
    for (const element of snapPoints) {
      element.addEventListener(
        "mouseover",
        () => {
          // betting hover

          if (element.getAttribute("hover") != null) {
            // this.setHovered(element.getAttribute("hover"), false);
          }
          // oval hover
          else {
            this.setHovered(element.getAttribute("bet_list"), true);
          }
        },
        false
      );
      element.addEventListener(
        "mouseout",
        () => {
          // betting hover
          // const _betList = element.getAttribute("bet_list");
          // const _outs = _betList.includes(" ") ? _betList.split(" ") : [_betList.trim()];

          // this.setHovered(this.selected.map((m) => m.refer).join(" "), true);
          const _betList = element.getAttribute("bet_list");
          const _outs = _betList.includes(" ") ? _betList.split(" ") : [_betList.trim()];

          for (const id of _outs) {
            const elements = document.querySelectorAll(`#${id}`);
            for (const element of elements) {
              element.classList.remove("hover-ovale-snap");
            }
          }

          this.$store.commit("setHovered", []);
        },
        false
      );
      element.addEventListener(
        "mousedown",
        (e) => {
          if (this.$store.state.roundStatus != "started") {
            return;
          }
          // if (arr.filter((v) => v.refer == e.target.id).length == 0){
          //     arr.push({
          //     refer: e.target.id,
          //     value: this.$store.state.coin,
          //     });
          //     this.selected = arr;
          this.setHovered(element.getAttribute("bet_list"), true);
          this.setSelected(element.getAttribute("bet_list"));
          this.$store.commit("setSelected", this.selected);
          setTimeout(() => {
            this.$store.commit("setHovered", []);
          }, 500);
          // }
          // else{
          //     const _arr = arr.filter((v) => v.refer != e.target.id);
          //     this.selected = _arr;
          //     this.$store.commit("setSelected", _arr);
          // }
        },
        false
      );
    }
  },
};
</script>
<style scoped>
.counter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute; /* 使其绝对定位 */
  background-color: transparent !important; /* 覆盖原有的背景色 */
 
}

.counter-button {
  width: 40px;
  height: 50px;
  font-size: 35px;
}

.counter-number {
  font-size: 35px;
  margin: 10px 0;
}
.mobile-ovale-snap {
  transform: rotate(90deg) translateY(-40%) translateX(-50px);
  transform-origin: left bottom;
}
</style>
