import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from 'vue-toast-notification';
import VueMobileDetection from "vue-mobile-detection";
import 'vue-toast-notification/dist/theme-sugar.css';
import "./assets/styles.css";
import './index.css';
import AppToastPlugin from './mixins/apptoast.js'

//const VueMobileDetection = require('vue-mobile-detection');

Vue.config.productionTip = false;
Vue.use(VueMobileDetection);
Vue.use(VueToast);
Vue.use(AppToastPlugin)

new Vue({
  router,
  store,
  
  render: (h) => h(App),
  errorCaptured(err, vm, info) {
    // 捕获错误,可以在这里进行错误上报等
    console.error('vue错误',err)
    // 重新加载Vue实例
    window.parent.postMessage({ type: 'vuehaventshow' }, '*')
    // window.postMessage({ type: 'vuerrorsave' }, '*')
  }
}).$mount("#app");
